.modal-container {
    position: fixed;
    top: 260px;
    right: 20px;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    z-index: 1000;
    background-color:lightgrey;
    width: 150px;
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
  }

  .modal-content h2 {
    color:crimson;
    font-size: 1rem;
  }

  .modal-content .product__description {
    font-size: 0.5rem;
    font-family: Inter,sans-serif;
  }
  