.profile-container {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    z-index: 1000;
    background-color:lightgrey;
    width: 150px;
  }
  
  .profile-content {
    display: flex;
    flex-direction: column;
  }

  .profile-content h1 {
    margin:10px;
    color:grey;
    font-size: 1rem;
  }
  